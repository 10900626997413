import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl } from "react-intl"

function SEO({ meta, page, intl: { formatMessage, locale } }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            de {
              title
              description
              author
              keywords
            }
            en {
              title
              description
              author
              keywords
            }
            pt {
              title
              description
              author
              keywords
            }
          }
        }
      }
    `
  )

  const metaDescription = site.siteMetadata[locale].description
  const keywords = site.siteMetadata[locale].keywords
  const title = `${formatMessage({ id: page })}`

  return (
    <Helmet
      htmlAttributes={{
        locale,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata[locale].title} | %s `}
      meta={[
        {
          name: "keywords",
          content: keywords,
        },
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: `${site.siteMetadata[locale].title} | ${title}`,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  page: "",
  meta: [],
}

SEO.propTypes = {
  page: PropTypes.string.isRequired,
  meta: PropTypes.array,
}

export default injectIntl(SEO)
