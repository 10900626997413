import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { injectIntl } from "react-intl"
import Img from "gatsby-image"
import { globalHistory } from "@reach/router"

import LanguageSwitch from "../language-switch"
import LocalizedLink from "../localized-link"
import MobileMenu from "../mobile-menu"

import routes from "../../../routes.json"
import "./styles.css"

function isActive(menuKey, locale) {
  const currentRoute = globalHistory.location.pathname
    .replace(`${locale}/`, "")
    .replace(/\/$/, "")
  const currentRoutes = Object.keys(routes[locale])

  let routeKey = "index"

  for (let i = 0; i < currentRoutes.length; i++) {
    if (routes[locale][currentRoutes[i]] === currentRoute) {
      routeKey = currentRoutes[i]
    }
  }

  return routeKey === menuKey
}

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

const Header = ({ siteTitle, intl: { formatMessage }, locale }) => (
  <header>
    <div className="logo">
      <LocalizedLink to="index">
        <Logo />
      </LocalizedLink>
    </div>
    <div className="menu">
      <div
        className={isActive("index", locale) ? "menu-item active" : "menu-item"}
      >
        <LocalizedLink to="index" label="home" />
      </div>
      <div
        className={isActive("about", locale) ? "menu-item active" : "menu-item"}
      >
        <LocalizedLink to="about" label="about" />
      </div>
      <div
        className={
          isActive("services", locale) ? "menu-item active" : "menu-item"
        }
      >
        <LocalizedLink to="services" label="services" />
      </div>
      <div
        className={
          isActive("contact", locale) ? "menu-item active" : "menu-item"
        }
      >
        <LocalizedLink to="contact" label="contact" />
      </div>
    </div>
    <div className="language-switch-wrapper">
      <LanguageSwitch locale={locale} />
    </div>
    <div className="mobile-menu-button">
      <MobileMenu />
    </div>
  </header>
)

export default injectIntl(Header)
