import React from "react"
import PropTypes from "prop-types"
import "./styles.css"

const ContentWrapper = ({ children }) => (
  <div className="content-wrapper">{children}</div>
)

ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ContentWrapper
