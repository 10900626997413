import React, { Component } from "react"

import { Location } from "@reach/router"

import routes from "../../../routes.json"
import LanguageImage from "../language-image"
import "./styles.css"

class LanguageSwitch extends Component {
  constructor(props) {
    super(props)
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this)
  }

  handleChangeLanguage(language, location, navigate, currentLocale) {
    const currentRoute = location.pathname
      .replace(`${currentLocale}/`, "")
      .replace(/\/$/, "")
    const currentRoutes = Object.keys(routes[currentLocale])
    let routeKey = "index"

    for (let i = 0; i < currentRoutes.length; i++) {
      if (routes[currentLocale][currentRoutes[i]] === currentRoute) {
        routeKey = currentRoutes[i]
      }
    }

    const newRoute = `/${language}${routeKey ? routes[language][routeKey] : ""}`
    navigate(newRoute)
  }

  renderLanguageChoice(language, index) {
    const { locale } = this.props
    return (
      <Location key={index}>
        {({ navigate, location }) => (
          <button
            className={
              language === locale ? "language-switch active" : "language-switch"
            }
            key={language}
            onClick={() =>
              this.handleChangeLanguage(language, location, navigate, locale)
            }
          >
            <LanguageImage language={language} active={locale === language} />
          </button>
        )}
      </Location>
    )
  }

  render() {
    const languages = Object.keys(routes)

    return languages.map((language, index) =>
      this.renderLanguageChoice(language, index)
    )
  }
}

export default LanguageSwitch
