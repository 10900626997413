import React from "react"
import { injectIntl } from "react-intl"

import LocalizedLink from "../localized-link"
import "./styles.css"

const Footer = ({ intl: { formatMessage } }) => (
  <footer>
    <LocalizedLink to="imprint" label="imprint" />
    <LocalizedLink to="privacy" label="privacy" />
  </footer>
)

export default injectIntl(Footer)
