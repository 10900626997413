import { Link } from "gatsby"
import React from "react"

import { injectIntl } from "react-intl"

import routes from "../../../routes.json"

const LocalizedLink = ({
  label,
  intl: { locale, formatMessage },
  to,
  children,
}) => (
  <Link to={`${locale}${routes[locale][to]}`}>
    {label ? formatMessage({ id: label }) : children}
  </Link>
)

export default injectIntl(LocalizedLink)
