import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./styles.css"

const FullWidthImage = ({ page = "index" }) => {
  let data = useStaticQuery(graphql`
    query {
      index: file(relativePath: { eq: "pink.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      about: file(relativePath: { eq: "cdc.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contact: file(relativePath: { eq: "ibrahim.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      services: file(relativePath: { eq: "national.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      className="full-width-image"
      fluid={data[page].childImageSharp.fluid}
    />
  )
}

export default FullWidthImage
