import React from "react"

import Pt from "../../images/pt.svg"
import De from "../../images/de.svg"
import En from "../../images/en.svg"

import "./styles.css"

const LanguageImage = ({ language = "de", active }) => {
  let svg

  switch (language) {
    case "de":
      svg = <De />
      break
    case "en":
      svg = <En />
      break
    case "pt":
      svg = <Pt />
      break
    default:
      svg = <De />
      break
  }

  return <div className={`language-image ${active ? "active" : ""}`}>{svg}</div>
}

export default LanguageImage
