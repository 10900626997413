export default {
  de: {
    about: "Über uns",
    contact: "Kontakt",
    imprint: "Impressum",
    home: "Home",
    privacy: "Rechtliche Hinweise",
    services: "Leistungen",

    welcome_to_eurobram: "Willkommen bei EUROBRAM GmbH",
    our_company_headline: "Unsere Firma",
    our_company_text:
      "EUROBRAM GmbH ist ein Dienstleistungsunternehmen für die pharmazeutische und agrochemische Industrie.",
    our_customers_headline: "Unsere Kunden",
    our_customers_text:
      "Unsere typischen Kunden entwickeln und produzieren Produkte für den brasilianischen Markt. Wir helfen, dass dringend benötigter Laborbedarf auch rasch am gewünschten Bestimmungsort ankommt.",
    our_competence_headline: "Unsere Kompetenz",
    our_competence_text:
      "Unsere Kernkompetenz liegt in der Beschaffung von Analysenstandards, Arzneimittelmustern und Labormaterialien für den brasilianischen Markt. Mit gut ausgebildeten und erfahrenen Teams in Deutschland und Brasilien begegnen wir den komplexen Anforderungen hinsichtlich Beschaffung, Dokumentation, rechtlicher Regelungen, Zollformalitäten und Transport.",

    initial_idea_headline: "Die Gründungsidee",
    initial_idea_text:
      "EUROBRAM GmbH wurde mit Sitz in Weimar gegründet. Die Idee zur Gründung des Unternehmens entstand aus zahlreichen Gesprächen mit Mitarbeitern der pharmazeutischen und agrochemischen Industrie. Insbesondere Mitarbeiter aus den Abteilungen von Forschung & Entwicklung, Qualitätskontrolle und Einkauf beklagten immer wieder, dass die Beschaffung von Analysenstandards, Arzneimittelmustern oder besonderen Labormaterialien in Brasilien langwierig und mit unvorhersehbaren Problemen mit dem Zoll verbunden wäre - sehr oft mit direkter Auswirkung (Verzögerungen) auf Projekte. Recherchen ergaben, dass die Ursache der Verzögerungen in unvollständiger oder unkorrekter Dokumentation, Nichteinhaltung von Bestimmungen oder fehlender Kundenorientierung begündet war. Hier setzt EUROBRAM GmbH an.",

    our_mission_headline: "Unsere Leitgedanken",
    our_mission_text:
      "Deshalb haben wir „Compliance and Results“ zu unserer Leitlinie gemacht. Das Wissen um die relevanten Bestimmungen und deren korrekte Anwendung sehen wir als Wettbewerbsvorteil. Entscheidend sind am Ende jedoch die Ergebnisse.",
    our_partners_in_brazil: "Unsere Partner in Brasilien",

    our_service_for_you_headline: "Unsere Leistungen für Sie",
    our_service_for_you_text_1:
      "Chemische, pharmazeutische und agrochemische Produkte werden durch eine Vielzahl von Bestimmungen reglementiert, die in Kombination mit Import- und Export-Aktivitäten ein komplexes Ausmaß annehmen können. Der Bezug von Analysenstandards oder Arzneimittelmustern über Ländergrenzen hinweg kann dadurch zu einem langwierigen Unterfangen werden. Wir wissen, welche Bedeutung Geschwindigkeit und Zuverlässigkeit für unsere Kunden hat. Deshalb setzen wir alles daran, dass die gewünschten Produkte rasch und sicher beim Kunden eintreffen. Wir sorgen dafür, dass die bestellten Produkte die erforderliche Dokumentation aufweisen. Über unser Netzwerk ermöglichen wir eine rasche Zollabfertigung und eine schnelle Zustellung an den Bestimmungsort.",
    our_service_for_you_text_2:
      "Wenn Sie einen Analysenstandard in Brasilien, ein Arzneimittel aus Brasilien oder eine HPLC Säule für Ihre Niederlassungen in Brasilien benötigen, treten Sie mit uns in Kontakt!",
    our_service_for_you_text_3:
      "Schildern Sie uns Ihre Fragestellung und senden Sie Ihre Email an:",

    contact_us_headline: "So können Sie uns erreichen",
    contact_us_text: "Wir freuen uns, mit Ihnen in Kontakt zu treten.",
    germany: "Deutschland",

    imprint_text_1:
      "EUROBRAM GmbH, vertreten durch die Geschäftsführerin Dr. Monika Auer, Papiergraben 1, 99425 Weimar, Deutschland, Tel: +49-3643-8599989",
    imprint_text_2: "Handelsregister-Nummer beim Amtsgericht Jena: HRB 507930",
    imprint_text_3: "Umsatzsteueridentifikations-Nummer: DE282978370",
    imprint_text_4:
      "Aufsichtsbehörde (§ 5 Abs. 1 Nr. 3 Telemediengesetz) für die Erlaubnis nach § 2 Chemikalienverbotsverordnung: kreisfreie Stadt Weimar als zuständige Untere Chemikaliensicherheitsbehörde, Schwanseestrasse 17, 99423 Weimar, Deutschland.",

    copyright_headline: "Urheberrechte",
    copyright_text:
      "Die Fotos der Bilderleiste sind von https://unsplash.com erworben und urheberrechtlich geschützt (Purple Pipette: © Louis Reed, DNA Genotyping and Sequencing High-throughput DNA processing is enabled by automated liquid-handling robots at the Cancer Genomics Research Laboratory, part of the National Cancer Institute's Division of Cancer Epidemiology and Genetics (DCEG): © National Cancer Institute, Clear glass bottle: © CDC, Assorted-color syringes on clear glass rack: © Ibrahim Boran).",

    disclaimer_headline: "Haftungshinweise",
    disclaimer_text:
      "Trotz sorgfältiger inhaltlicher Kontrolle kann keine Gewähr für die Fehlerfreiheit gegeben werden. Jegliche Haftung für direkte oder indirekte Schäden aus der Benutzung dieser Website ist ausgeschlossen. Bei Verweisen auf Webportale Dritter übernimmt EUROBRAM GmbH keine Verantwortung für deren Inhalte. Links/Verweise werden lediglich als Annehmlichkeit zur Verfügung gestellt. Es wird ausdrücklich erklärt, dass zum Zeitpunkt der Erstellung der Verweise keine illegalen Inhalte erkennbar waren.",
  },
  en: {
    about: "About us",
    contact: "Contact",
    imprint: "Imprint",
    home: "Home",
    privacy: "Legal notices",
    services: "Services",

    welcome_to_eurobram: "Welcome to EUROBRAM GmbH",
    our_company_headline: "Our Company",
    our_company_text:
      "EUROBRAM GmbH is a service provider for the pharmaceutical and agrochemical industry.",
    our_customers_headline: "Our Customers",
    our_customers_text:
      "Our typical customer develops and manufactures products for the Brazilian Market. We help our customers getting urgently needed lab items as quick as possible.",
    our_competence_headline: "Our Competence",
    our_competence_text:
      "Our core competence is the supply of analytical standards, drug samples and lab materials for the Brazilian Market. With well-trained and experienced teams in Germany and Brazil, we meet the complex requirements for procurement, documentation, legal regulations, customs clearance and transportation.",

    initial_idea_headline: "The Initial Idea",
    initial_idea_text:
      "EUROBRAM GmbH was founded in Weimar. The initiative was given by various conversations with representatives of pharmaceutical and agrochemical companies. Particularly, members of research, development and quality control expressed their dissatisfaction in the timelines and customs problems encountered with respect to procurement of laboratory items in Brazil, which had significant impact on the timelines of projects. Investigations revealed that very often the root cause was an inadequate documentation or simply a lack of customer focus. This was the starting point for EUROBRAM GmbH.",

    our_mission_headline: "Our Mission",
    our_mission_text:
      "We have placed „Compliance and Results” as the core of our activities. We consider our profound knowledge of relevant regulations followed by compliant executions as a competitive advantage. At the end it's the results that make the difference.",
    our_partners_in_brazil: "Our Partners in Brazil",

    our_service_for_you_headline: "Our Service for You",
    our_service_for_you_text_1:
      "Chemical, pharmaceutical and agrochemical products need to follow various regulations. In combination with import- and export-activities getting a specific substance or product can become cumbersome. We are aware about the importance of speed and reliability to our customers. It is our top priority that our customers receive our products as fast as possible. We take care about the documentation required and throughout our network we enable a fast customs procedure and delivery to the final destination.",
    our_service_for_you_text_2:
      "If you need analytical standards in Brazil, a drug product from Brazil or for example a difficult to get HPLC-column for your subsidiary in Brazil, get in touch with us.",
    our_service_for_you_text_3:
      "Let us know your inquiries and send an email to:",

    contact_us_headline: "How you can get in contact with us",
    contact_us_text: "Please, address your inquiries and suggestions to:",
    germany: "Germany",

    imprint_text_1:
      "EUROBRAM GmbH, represented by the Managing Director Dr. Monika Auer, Papiergraben 1, 99425 Weimar, Germany, Tel: +49-3643-8599989",
    imprint_text_2:
      "Register of Companies at the District Court Jena, Germany: No. HRB 507930 ",
    imprint_text_3: "VAT-No: DE282978370",
    imprint_text_4:
      "The Competent Authority for the permit under § 2 of the ChemVerbotsV (Trade with Chemicals): Independent City of Weimar in person of the “Untere Chemikaliensicherheitsbehörde” , Schwanseestrasse 17, 99423 Weimar, Germany.",

    copyright_headline: "Copyright",
    copyright_text:
      "The photos of the picture frame are acquired from https://unsplash.com and are copyrighted (Purple Pipette: © Louis Reed, DNA Genotyping and Sequencing High-throughput DNA processing is enabled by automated liquid-handling robots at the Cancer Genomics Research Laboratory, part of the National Cancer Institute's Division of Cancer Epidemiology and Genetics (DCEG): © National Cancer Institute, Clear glass bottle: © CDC, Assorted-color syringes on clear glass rack: © Ibrahim Boran).",

    disclaimer_headline: "Disclaimer",
    disclaimer_text:
      "The English Language version is provided for convenience only and is therefore not binding. The authoritative and legal wording is the German version. Despite of careful control no representation is made or warranty given, either expressly or tacitly, for the completeness or correctness of this website. Any liability for direct or indirect damages resulting from the use of the website is excluded. Links/references to other web-portals are given for convenience only. EUROBRAM GmbH will not accept any responsibility or any liability for damage resulting from the use of contents of web-portals of third parties, of whatever form. At the moment of the linking no illegal content could be observed.",
  },
  pt: {
    about: "Quem somos",
    contact: "Contato",
    imprint: "Responsabilidades / Impressum",
    home: "Home",
    privacy: "Informações Legais",
    services: "Nossos serviços",

    welcome_to_eurobram: "Bem vindo à EUROBRAM GmbH",
    our_company_headline: "Nossa Empresa",
    our_company_text:
      "EUROBRAM GmbH é uma empresa prestadora de serviços para a indústria farmacêutica e agroquímica.",
    our_customers_headline: "Nossos Clientes",
    our_customers_text:
      "Nosso trabalho proporciona a nossos clientes conseguir itens de laboratório de grande necessidade com o mínimo de tempo possível.",
    our_competence_headline: "Nossa Competência",
    our_competence_text:
      "Nossa competência principal é o fornecimento de padrões analíticos, amostras de medicamentos e materiais de laboratório para empresas que desenvolvem e fabricam produtos para o mercado brasileiro. Com equipes bem treinadas e experientes na Alemanha e no Brasil, resolvemos as complexas exigências referentes à documentações para aquisição de produtos, regulamentos legais, formalidades aduaneiras e de transporte.",

    initial_idea_headline: "A Ideia Inicial",
    initial_idea_text:
      "EUROBRAM GmbH foi fundada em Weimar na Alemanha. A ideia inicial surgiu após vários contatos com representantes de empresas farmacêuticas e agroquímicas. Membros da equipe de pesquisa, de desenvolvimento e controle de qualidade, particularmente expressaram a sua insatisfação na demora da entrega e outros problemas encontrados em relação à aquisição de itens de laboratório no Brasil, os quais têm impacto significativo sobre os cronogramas de projetos. As investigações revelaram que muitas vezes a causa estava em documentação inadequada ou simplesmente na falta de foco no cliente. Assim surgiu a EUROBRAM GmbH.",

    our_mission_headline: "Nossa Missão",
    our_mission_text:
      'Colocamos "o cumprimento dos objetivos e dos resultados" como centro de nossas atividades. Consideramos o nosso expressivo conhecimento nos processos legais, bem como a execução compatível como uma vantagem competitiva. Com base em nossos resultados queremos ser avaliados.',
    our_partners_in_brazil: "Nossos Parceiros no Brasil",

    our_service_for_you_headline: "Nossos Serviços para a sua Empresa",
    our_service_for_you_text_1:
      "A combinação da atividade de importação e exportação na aquisição de substâncias químicas, farmacêuticas e de produtos agroquímicos pode se tornar complicada se não seguir as normas da legislação específica. Temos consciência da importância do tempo neste processo e também na confiabilidade de nossos clientes. Nossa prioridade é de que nossos clientes recebam os nossos produtos o mais rápido possível. Cuidamos de toda a documentação necessária e a nossa rede de contatos nos permite a realização de um procedimento personalizado e rápido até a entrega no destino final.",
    our_service_for_you_text_2:
      "Se sua empresa precisa de padrões analíticos no Brasil, medicamentos do Brasil ou por exemplo uma coluna de HPLC para a sua subsidiária no Brasil, entre em contato conosco!",
    our_service_for_you_text_3:
      "Estamos prontos para responder suas perguntas (possível nos três idiomas citados acima) através do endereço de e-mail:",

    contact_us_headline: "Entre em contato conosco",
    contact_us_text:
      "Teremos o prazer de entrar em contato com você também. Por favor, envie suas perguntas e sugestões para:",
    germany: "Alemanha",

    imprint_text_1:
      "EUROBRAM GmbH, representada através da Diretora Dr. Monika Auer, Papiergraben 1, CEP: 99425 Weimar, Alemanha, Tel: +49-3643-8599989",
    imprint_text_2:
      "Registro da empresa no Tribunal Distrital Jena, Alemanha No: HRB 507930",
    imprint_text_3: "VAT-No: DE282978370",
    imprint_text_4:
      'Autoridade competente para a autorização nos termos do § 2 do ChemVerbotsV (Comércio de Produtos Químicos): Cidade Independente de Weimar na pessoa da "Untere Chemikaliensicherheitsbehörde", Schwanseestrasse 17, CEP: 99423 Weimar, Alemanha',

    copyright_headline: "Direitos Autorais",
    copyright_text:
      "As fotos nos quadros ao lado foram compradas na https://unsplash.com e são protegidas por direitos autorais (Purple Pipette: © Louis Reed, DNA Genotyping and Sequencing High-throughput DNA processing is enabled by automated liquid-handling robots at the Cancer Genomics Research Laboratory, part of the National Cancer Institute's Division of Cancer Epidemiology and Genetics (DCEG): © National Cancer Institute, Clear glass bottle: © CDC, Assorted-color syringes on clear glass rack: © Ibrahim Boran).",

    disclaimer_headline: "Renúncia",
    disclaimer_text:
      "A versão no idioma Português é apenas para orientação. Para todos os fins legais a versão em idioma Alemão é a única versão oficial. Apesar do cuidadoso controle não podemos garantir a ausência de erros. É excluída qualquer responsabilidade por danos diretos ou indiretos resultantes do uso do website. EUROBRAM GmbH não assume nenhuma responsabilidade pelo conteúdo dos links de terceiros. No momento do acesso inicial nenhum conteúdo ilegal foi detectado.",
  },
}
