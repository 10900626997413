import React, { useState } from "react"

import { injectIntl } from "react-intl"

import LocalizedLink from "../localized-link"
import LanguageSwitch from "../language-switch"

import "./style.css"

const MobileMenu = ({ intl: { locale } }) => {
  // Declare a new state variable, which we'll call "count"
  const [displayMenu, setDisplayMenu] = useState(false)

  return (
    <div className="mobile-menu">
      <button
        className="hamburger hamburger--collapse"
        type="button"
        onClick={() => setDisplayMenu(!displayMenu)}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      {displayMenu && (
        <div className="mobile-menu-content">
          <div className="mobile-menu-header">
            <button
              className="hamburger hamburger--collapse is-active"
              type="button"
              onClick={() => setDisplayMenu(!displayMenu)}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
          <div className="mobile-menu-body">
            <button
              className="mobile-menu-item"
              onClick={() => setDisplayMenu(!displayMenu)}
            >
              <LocalizedLink to="index" label="home" />
            </button>
            <button
              className="mobile-menu-item"
              onClick={() => setDisplayMenu(!displayMenu)}
            >
              <LocalizedLink to="about" label="about" />
            </button>
            <button
              className="mobile-menu-item"
              onClick={() => setDisplayMenu(!displayMenu)}
            >
              <LocalizedLink to="services" label="services" />
            </button>
            <button
              className="mobile-menu-item"
              onClick={() => setDisplayMenu(!displayMenu)}
            >
              <LocalizedLink to="contact" label="contact" />
            </button>
            <div className="mobile-menu-item">
              <LanguageSwitch locale={locale} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default injectIntl(MobileMenu)
