import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { IntlProvider } from "react-intl"

import translations from "../../i18n/translations"

import Footer from "../footer"
import Header from "../header"
import "./styles.css"

const Layout = ({ children, locale = "de" }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          de {
            title
            description
            author
          }
          en {
            title
            description
            author
          }
          pt {
            title
            description
            author
          }
        }
      }
    }
  `)

  return (
    <IntlProvider locale={locale} messages={translations[locale]}>
      <div className="main-wrapper">
        <Header
          siteTitle={data.site.siteMetadata[locale].title}
          locale={locale}
        />
        <main>{children}</main>
        <Footer />
      </div>
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string,
}

export default Layout
